import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDropdown } from '@/layouts/page-header/use-dropdown';
import { DropdownElement } from '@/util/interfaces';

import { PageHeaderDropdown } from './page-header-dropdown';

export const CustomerSupportButton = () => {
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { isOpen, toggle, open, close, handleKeyDown } = useDropdown(dropdownRef);

  const supportOptions: DropdownElement[] = [
    {
      text: t('navbar.menu.contact-us'),
      onClick: () => window.open('mailto:info@consigli.app', '_blank'),
    },
    {
      text: t('navbar.menu.tutorial'),
      onClick: () => window.open('https://www.consigli.no/tutorials', '_blank'),
    },
  ];

  return (
    <div className="relative" ref={dropdownRef} onMouseLeave={close}>
      <button
        className="py-1.5 px-2 cursor-pointer hover:bg-day-light-3 rounded-full relative"
        onClick={toggle}
        onMouseEnter={open}
        onKeyDown={handleKeyDown}
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <i className="fas fa-circle-question fa-xl text-day-neutral-dark" aria-hidden="true" />
      </button>
      <PageHeaderDropdown
        header={t('navbar.menu.help')}
        elements={supportOptions}
        className={`right-0 ${isOpen ? 'block' : 'hidden'} min-w-[200px] z-50`}
      />
    </div>
  );
};
