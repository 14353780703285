import { LoadingArea } from '@consigli/facade';
import {
  useProjectId,
  useCeilingGridId,
  useCeilingGridResults,
  useLazyGetCeilingGridResultsQuery,
} from '@consigli/hooks';
import { convertFileToDocument } from '@consigli/types';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WaitingForAnalysis } from '@/components/waiting-for-analysis';
import { Breadcrumb } from '@/features/breadcrumb/breadcrumb';
import { DocumentListWrapper } from '@/features/document-list/document-list-wrapper';
import { WorkspaceProvider } from '@/layouts/workspace-layout/use-workspace-context';
import { WorkspaceLayout } from '@/layouts/workspace-layout/workspace-layout';

export const CeilingGridResultPage: FC = () => {
  const projectId = useProjectId();
  const ceilingGridId = useCeilingGridId();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const { results, totalCount, isLoading, isFetching } = useCeilingGridResults({
    projectId,
    ceilingGridId,
    page: page,
  });

  const [fetchAllCeilingGridResults, { isFetching: isFetchingAllResults }] =
    useLazyGetCeilingGridResultsQuery();

  const handleFetchAllFiles = useCallback(async () => {
    const ceilingGridFilesResponse = await fetchAllCeilingGridResults({
      projectId,
      ceilingGridId,
      page: 'all',
    }).unwrap();
    return ceilingGridFilesResponse.results.map((result) => convertFileToDocument(result.file));
  }, [fetchAllCeilingGridResults, projectId, ceilingGridId]);

  return (
    <>
      <Breadcrumb label={t('optimization-tabs.results')} />
      <WorkspaceProvider>
        <WorkspaceLayout>
          {isLoading ? (
            <LoadingArea title={t('document-list.loading')} />
          ) : results.length === 0 ? (
            <WaitingForAnalysis />
          ) : (
            <DocumentListWrapper
              files={results.map((result) => convertFileToDocument(result.file))}
              headerTitle={t('document-list.results')}
              paginatedCount={totalCount}
              totalCount={totalCount}
              page={page}
              setPage={setPage}
              isFetching={isFetching}
              fetchAllFiles={handleFetchAllFiles}
              isFetchingAllFiles={isFetchingAllResults}
              packageId={ceilingGridId}
            />
          )}
        </WorkspaceLayout>
      </WorkspaceProvider>
    </>
  );
};
