import { Blob } from '@consigli/types';
import { TFunction } from 'i18next';

import { getStructure } from '@/pages/insight/folders/util/folders-structure';

/**
 * Tree type representing an NS3451 tree directory. This is a special tree type
 * that has some specific traits:
 *
 * 1. All nodes are either a File or a Folder, but all true leaf nodes are files.
 * 2. A leaf folder is any parent of a File, as all files are at the bottom.
 */
export type Tree = {
  children: FileCollection;
};

export type TreeEntry = TreeFile | TreeFolder;
type NodeProps = {
  id: string;
  level?: number;
  toDisplayString: (t: TFunction) => string;
};

export type FileCollection = Map<string, TreeEntry>;
export type TreeFolder = NodeProps & { children: FileCollection };
export type TreeFile = NodeProps & { blob: Blob };

export function isTreeFolder(node: TreeEntry): node is TreeFolder {
  return 'children' in node;
}

export function isTreeFile(node: TreeEntry): node is TreeFile {
  return 'blob' in node;
}

export type TreeNode = {
  id: string;
  level?: number;
  children: Map<string, TreeNode>;
  toDisplayString?: (t: TFunction) => string;
};

/**
 * Perform a top-down traversal of the entire tree. Child ordering is determined by the
 * insertion order of the child map.
 */
export function visit(tree: Tree, onEntry: (entry: TreeEntry) => void) {
  function visitChild(child: TreeEntry) {
    onEntry(child);
    if (isTreeFolder(child)) {
      child.children.forEach(visitChild);
    }
  }
  tree?.children?.forEach(visitChild);
}

/**
 * Get all non-leaf nodes in the tree. For our specialized tree format this means
 * getting all the folders.
 */
export function getNonLeafNodes(tree: Tree): TreeFolder[] {
  const entries: TreeFolder[] = [];
  visit(tree, (entry) => {
    if (isTreeFolder(entry)) {
      entries.push(entry);
    }
  });
  return entries;
}

export function createTreeFile(blob: Blob): TreeFile {
  return {
    toDisplayString: () => blob.name,
    id: blob.id,
    blob,
  };
}

export function isLeafNode(node: TreeFolder): boolean {
  for (const child of node.children.values()) {
    if (isTreeFolder(child)) {
      return false;
    }
  }
  return true;
}

const LEVEL_1 = 1;
const DIGIT_PATTERN = '\\d{1}$';

const filterFilesByLevel = (files: Record<string, TreeNode>, level: number) =>
  Object.values(files).filter((file) => file.level === level);

const filterFilesByParentId = (files: Record<string, TreeNode>, parentId: string) =>
  Object.values(files).filter((file) => file.id.match(`^${parentId}${DIGIT_PATTERN}`));

const addChildrenToParent = (parent: TreeNode, children: TreeNode[]) =>
  children.forEach((child) => parent.children.set(child.id, child));

const updateTreeStructure = (files: Record<string, TreeNode>) => {
  const treeStructure = new Map();
  const levelOneFolders = filterFilesByLevel(files, LEVEL_1);

  levelOneFolders.forEach((rootNode) => {
    treeStructure.set(rootNode.id, rootNode);

    const levelTwoFolders = filterFilesByParentId(files, rootNode.id);
    addChildrenToParent(rootNode, levelTwoFolders);

    levelTwoFolders.forEach((child) => {
      const levelThreeFolders = filterFilesByParentId(files, child.id);
      addChildrenToParent(child, levelThreeFolders);
    });
  });
  return { children: treeStructure };
};

const updateStructureWithTranslater = (structure: Record<string, TreeNode>) => {
  Object.values(structure).filter(
    (file) => (file.toDisplayString = (t: TFunction) => t(`ns3451.${file.id}`)),
  );
  return structure;
};

export const createTree = (): { children: Map<string, TreeNode> } => {
  const structure = updateStructureWithTranslater(getStructure());
  return updateTreeStructure(structure);
};
