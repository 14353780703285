import { Finding } from '@consigli/types';

import { AccordionProvider } from '@/components/accordion/use-accordion-context';

import { ExportButton } from './export-button';
import { FindingsAccordion } from './findings-accordion';

export interface ConflictTypeCountCategory {
  count: number;
  subcounts: Record<string, number>;
}

interface FindingsSidebarProps {
  conflictTypeCounts: Record<string, ConflictTypeCountCategory>;
  downloadFindingsForExport: (isFilterNeeded: boolean) => Promise<Finding[]>;
  isDownloadingFindingsForExport: boolean;
}

export const FindingsSidebar = (props: FindingsSidebarProps) => {
  const { conflictTypeCounts, downloadFindingsForExport, isDownloadingFindingsForExport } = props;

  return (
    <>
      <AccordionProvider>
        <FindingsAccordion conflictTypeCounts={conflictTypeCounts} />
      </AccordionProvider>
      <ExportButton
        classNames="w-full"
        downloadFindings={downloadFindingsForExport}
        isDownloadingFindings={isDownloadingFindingsForExport}
      />
    </>
  );
};
