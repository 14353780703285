import { LoadingArea } from '@consigli/facade';
import {
  useProjectId,
  useLayoutId,
  useLayoutResults,
  useGetLayoutOptimizationQuery,
  useMyUser,
} from '@consigli/hooks';
import { convertFileToDocumentWithFileType } from '@consigli/types';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AdminBanner } from '@/components/admin-banner';
import { WaitingForAnalysis } from '@/components/waiting-for-analysis';
import { Breadcrumb } from '@/features/breadcrumb/breadcrumb';
import { WorkspaceProvider } from '@/layouts/workspace-layout/use-workspace-context';
import { WorkspaceLayout } from '@/layouts/workspace-layout/workspace-layout';

import EngineeringResultFiles from './engineering-result-files';

export const EngineeringResultPage: FC = () => {
  const projectId = useProjectId();
  const layoutId = useLayoutId();
  const { t } = useTranslation();
  const { user } = useMyUser();

  const { results, isLoading, isFetching } = useLayoutResults({
    projectId,
    layoutId,
    page: 'all',
  });

  const { data: layout } = useGetLayoutOptimizationQuery({ projectId, layoutId });

  const isPublished = layout ? layout.published : false;

  const waitingForAnalysis = useMemo(() => {
    if (user.isSuperuser) {
      return false;
    }
    if (isPublished) {
      return false;
    }
    return true;
  }, [isPublished, user.isSuperuser]);

  return (
    <>
      <Breadcrumb label={t('optimization-tabs.results')} />
      <WorkspaceProvider>
        <WorkspaceLayout hideTabs={waitingForAnalysis}>
          {waitingForAnalysis ? (
            <WaitingForAnalysis />
          ) : isLoading ? (
            <LoadingArea title={t('document-list.loading')} />
          ) : results.length === 0 ? (
            <div className="flex justify-center items-center w-full h-full text-[20px]">
              {t('document-list.no-results')}
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <AdminBanner user={user} published={isPublished} />
              <EngineeringResultFiles
                files={results.map((result) =>
                  convertFileToDocumentWithFileType(result.file, result.type),
                )}
                isFetching={isFetching}
              />
            </div>
          )}
        </WorkspaceLayout>
      </WorkspaceProvider>
    </>
  );
};
