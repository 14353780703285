import { Button } from '@consigli/facade';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LiaLongArrowAltLeftSolid } from 'react-icons/lia';
import { useParams } from 'react-router';

import { DownloadFileButton } from '@/components/download-file-button';
import { IfcContainer } from '@/components/ifc-viewer/container';
import { ImageContainer } from '@/components/image-viewer/image-container';
import { DocumentViewer } from '@/features/document-viewer/document-viewer';
import { useViewer } from '@/features/document-viewer/use-viewer';
import { useViewerContext } from '@/features/document-viewer/use-viewer-context';
import { isMsWordType, isIFCDocumentType, isImageDocumentType } from '@/util/document-check';

import { isOptimizationService } from './document-preview';
import { SinglePdfViewer } from './single-pdf-viewer';

export const DocumentFullscreen = () => {
  const { document } = useViewerContext();
  const { close } = useViewer();
  const { t } = useTranslation();
  const { serviceName } = useParams();

  const FullScreenViewerComponent = useCallback(() => {
    if (!document) {
      return (
        <div className="text-white text-center pt-[50%]">
          {t('document-viewer.no-document-selected')}
        </div>
      );
    }
    if (isImageDocumentType(document.name)) {
      return <ImageContainer document={document} />;
    }
    if (
      (isOptimizationService(serviceName) && isMsWordType(document.name)) ||
      (isMsWordType(document.name) && !document.convertedPdfFileId)
    ) {
      return <DocumentViewer document={document} isPreview={true} />;
    }
    if (isIFCDocumentType(document.name)) {
      return <IfcContainer document={document} />;
    }
    return <SinglePdfViewer document={document} isPreview={false} />;
  }, [document, t, serviceName]);

  return (
    <>
      <div className="flex flex-col z-[50] left-0 top-10 fixed cursor-default w-screen h-screen bg-day-light-4">
        <div className="w-full h-4 bg-gradient-to-b from-[#EDF2F7] to-[#F9FBFC] flex items-center justify-center"></div>
        <div className="flex flex-row justify-between items-center h-16 bg-day-light-4 px-8">
          <Button tertiary rounded className="font-light hover:bg-day-light-3" onClick={close}>
            <LiaLongArrowAltLeftSolid />
            {t('findings.buttons.back')}
          </Button>
          <div className="text-day-neutral-subtle text-xl font-medium">{document?.name}</div>
          {document && (
            <DownloadFileButton document={document} className="" size={20} label="Download" />
          )}
        </div>
        <div className="justify-center h-[calc(100vh_-_13rem)] flex overflow-hidden">
          <FullScreenViewerComponent />
        </div>
        {document && isIFCDocumentType(document.name) && (
          <>
            <div className="w-full h-4 bg-gradient-to-b from-[#EDF2F7] to-[#F9FBFC] flex items-center justify-center"></div>
            <div className="w-full h-16 bg-day-light-4 flex items-center justify-center opacity-30">
              {t('document-viewer.zoom-text')}
            </div>
          </>
        )}
      </div>
    </>
  );
};
