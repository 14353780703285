import { LoadingArea } from '@consigli/facade';
import { useProjectId, useServiceName } from '@consigli/hooks';
import { useTranslation } from 'react-i18next';

import { useNavigationHistory } from '@/features/package-creation/use-navigation-history';
import { Nav } from '@/layouts/nav';

interface InsightWizardLayoutProps {
  isProcessing: boolean;
  stepsRenderer: JSX.Element;
  messageHeader?: JSX.Element;
  isUploading: boolean;
  progress: number;
}

export const WizardLayout = ({
  isProcessing,
  stepsRenderer,
  messageHeader,
  isUploading,
  progress,
}: InsightWizardLayoutProps) => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const serviceName = useServiceName();
  const { wizardBackButtonClick } = useNavigationHistory();

  return (
    <div className="w-full flex flex-col min-h-[calc(100vh-theme(space.14))] bg-day-light-4">
      <Nav projectId={projectId} handleClick={wizardBackButtonClick} />
      <div className="flex flex-col flex-1 items-center px-4 py-12 w-full sm:w-[30rem] mx-auto">
        <div className="mb-10 font-semibold text-2xl text-day-neutral-dark">
          {t(`package-creation.new.${serviceName}`)}
        </div>
        <>
          {isUploading || isProcessing ? (
            <LoadingArea
              className="mt-20"
              title={t('document-upload.uploading')}
              message={progress < 1 ? t('document-upload.initialising') : `${progress}%`}
            />
          ) : (
            <>
              {messageHeader}
              {stepsRenderer}
            </>
          )}
        </>
      </div>
    </div>
  );
};
