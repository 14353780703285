import { LoadingArea } from '@consigli/facade';
import { useProjectId, useServiceName } from '@consigli/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigationHistory } from '@/features/package-creation/use-navigation-history';
import { Nav } from '@/layouts/nav';

import { ComparisonSteps } from './compare/comparison-steps';
import { CompareProvider } from './compare/use-compare-packages';
import { useUploadProgress } from '../hooks/use-upload-progress';

export const ComparisonWizard = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const serviceName = useServiceName();
  const { wizardBackButtonClick } = useNavigationHistory();

  const [isComparing, setIsComparing] = useState(false);
  const [targetCounter, setTargetCounter] = useState(0);
  const [currentCounter, setCurrentCounter] = useState(0);

  const { isUploading, progress } = useUploadProgress(currentCounter, targetCounter);

  return (
    <div className="w-full flex flex-col min-h-[calc(100vh-theme(space.14))] bg-day-light-4">
      <Nav projectId={projectId} handleClick={wizardBackButtonClick} />
      <div className="flex flex-col flex-1 items-center px-4 py-12 w-full sm:w-[31rem] mx-auto">
        <div className="mb-10 font-semibold text-2xl text-day-neutral-dark">
          <>{t(`package-creation.new.${serviceName}`)}</>
        </div>
        <>
          {isComparing || isUploading ? (
            <LoadingArea
              className="mt-20"
              title={t('document-upload.uploading')}
              message={progress < 1 ? t('document-upload.initialising') : `${progress}%`}
            />
          ) : (
            <CompareProvider>
              <ComparisonSteps
                setIsComparing={setIsComparing}
                setCurrentCounter={setCurrentCounter}
                setTargetCounter={setTargetCounter}
              ></ComparisonSteps>
            </CompareProvider>
          )}
        </>
      </div>
    </div>
  );
};
