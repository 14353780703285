import {
  useProjectId,
  useServiceName,
  useGetCeilingGridQuery,
  useGetCeilingGridResultsQuery,
  useCeilingGridId,
} from '@consigli/hooks';
import { useMemo } from 'react';
import { Outlet } from 'react-router';

import { Breadcrumb } from '@/features/breadcrumb/breadcrumb';
import { DocumentListProvider } from '@/features/document-list/document-list-context';
import { Route } from '@/routes';

export const CeilingGridDetailWrapper = () => {
  const projectId = useProjectId();
  const ceilingGridId = useCeilingGridId();
  const serviceName = useServiceName();

  const { data: ceilingGrid } = useGetCeilingGridQuery({ projectId, ceilingGridId });
  const { data: ceilingGridResults } = useGetCeilingGridResultsQuery({
    projectId,
    ceilingGridId,
    page: 'all',
  });

  const hasResults = useMemo(() => {
    return ceilingGridResults && ceilingGridResults.results
      ? ceilingGridResults.results.length > 0
      : false;
  }, [ceilingGridResults]);

  const navRoute = useMemo(() => {
    return `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${ceilingGridId}/${hasResults ? Route.RESULTS : Route.INPUT}`;
  }, [projectId, serviceName, ceilingGridId, hasResults]);

  return (
    <>
      <Breadcrumb label={ceilingGrid?.name || ''} to={navRoute} />
      <DocumentListProvider>
        <Outlet />
      </DocumentListProvider>
    </>
  );
};
