import { useMyUser } from '@consigli/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Breadcrumb } from '@/features/breadcrumb/breadcrumb';
import { CenteredLayout } from '@/layouts/centered-content';
import { FluidLayout } from '@/layouts/fluid-content';
import { Nav } from '@/layouts/nav';

import { UserProfileInviteList } from './user-profile-invite-list';
import { UserProfileProjectAccessList } from './user-profile-project-access-list';

export const UserProfilePage: FC = () => {
  const { user } = useMyUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Breadcrumb label={t('navbar.breadcrumbs.myprofile')} to={`/profile`} />
      <FluidLayout>
        <CenteredLayout>
          <Nav handleClick={() => navigate('/projects')} isProfilePage={true} />
          <div className="flex w-full max-w-[600px] justify-center align-center p-12 mx-auto">
            <div className="flex flex-col w-full">
              <h1 className="flex text-3xl font-medium">{user.firstName + ' ' + user.lastName}</h1>
              <div className="flex justify-between mt-6">
                <div className="flex flex-col">
                  <p className="text-sm text-day-neutral-subtle">{t('profile.email')}</p>
                  <b>{user.email}</b>
                </div>
                <div className="flex flex-col items-end">
                  <p className="text-sm text-day-neutral-subtle">{t('profile.role')}</p>
                  <b>
                    {user.isSuperuser
                      ? 'SuperUser 🦸‍♂️'
                      : user.isOrgAdmin
                        ? t('profile.admin')
                        : t('profile.user')}
                  </b>
                </div>
              </div>
              <div className="border-b border-dashed border-day-secondary my-6"></div>
              {!user.isSuperuser && <UserProfileInviteList />}
              <UserProfileProjectAccessList />
            </div>
          </div>
        </CenteredLayout>
      </FluidLayout>
    </>
  );
};
