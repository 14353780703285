import { UseStepContext } from '@consigli/facade';
import { useProjectId, useServiceName } from '@consigli/hooks';
import { ServiceId, ServiceName } from '@consigli/types';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { useCreateInsightPackage } from '@/features/package-creation/hooks/use-create-insight-package';
import { Route, ServiceIdIndexValue } from '@/routes';

import { CompareButtons } from './compare-buttons';
import { PACKAGE_TYPE, useCompareContext } from './use-compare-packages';
import { ExistingPackage } from '../secondary/existing-package';
import { NewPackage } from '../secondary/new-package';
import { SecondaryService } from '../secondary/secondary-service';
import { SecondaryTab } from '../secondary-tab';

interface ComparePackagesProps {
  setIsComparing: Dispatch<SetStateAction<boolean>>;
  setCurrentCounter: Dispatch<SetStateAction<number>>;
  setTargetCounter: Dispatch<SetStateAction<number>>;
  context: UseStepContext;
}

export const ComparePackages = ({
  setIsComparing,
  context,
  setCurrentCounter,
  setTargetCounter,
}: ComparePackagesProps) => {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const serviceName = useServiceName();
  const serviceId = ServiceIdIndexValue[serviceName] as ServiceId;

  const {
    primaryData,
    primaryFiles,
    setSecondaryData,
    secondaryFiles,
    selectedComparisonPackage,
    compareServiceName,
    packageType,
  } = useCompareContext();

  const { createDocumentPackage, uploadFiles, comparePackages } = useCreateInsightPackage({
    setCurrentCounter,
    setTargetCounter,
  });
  const { handleSubmit, getValues } = useFormContext<FieldValues>();

  const compareServiceId = useMemo(
    () => ServiceIdIndexValue[compareServiceName?.value as ServiceName],
    [compareServiceName?.value],
  );
  const tab = useCallback(
    (serviceId: ServiceId) => {
      return [
        {
          id: 0,
          label: t('package-creation.compare-existing'),
          type: PACKAGE_TYPE.EXISTING,
          content: <ExistingPackage></ExistingPackage>,
        },
        {
          id: 1,
          label: t('package-creation.upload-new'),
          type: PACKAGE_TYPE.NEW,
          content: <NewPackage compareServiceId={serviceId}></NewPackage>,
        },
      ];
    },
    [t],
  );

  const onSubmit = useCallback(
    async (data: FieldValues, e?: React.BaseSyntheticEvent) => {
      try {
        e?.preventDefault();
        setSecondaryData(data);
        setIsComparing(true);
        let response;
        const primaryPackageId = await createDocumentPackage(
          serviceId,
          primaryData.iterationName,
          primaryData.language,
        );
        if (primaryPackageId) {
          await uploadFiles(primaryFiles, primaryPackageId);
        }
        if (packageType == PACKAGE_TYPE.EXISTING && selectedComparisonPackage && primaryPackageId) {
          response = await comparePackages(primaryPackageId, selectedComparisonPackage.id);
          setIsComparing(false);
          navigate(
            `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${primaryPackageId}/${Route.DASHBOARD}`,
          );
        } else {
          if (packageType == PACKAGE_TYPE.NEW && compareServiceId) {
            const secondaryPackageId = await createDocumentPackage(
              compareServiceId,
              getValues('secondaryPackageName'),
              primaryData.language,
            );
            if (secondaryPackageId) {
              await uploadFiles(secondaryFiles, secondaryPackageId);
              if (primaryPackageId && secondaryPackageId) {
                response = await comparePackages(primaryPackageId, secondaryPackageId);
                setIsComparing(false);
                navigate(
                  `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${primaryPackageId}/${Route.DASHBOARD}`,
                );
              }
            }
          }
        }
        if (response) toast.success(t('package-creation.success-analyzed'));
      } catch (error) {
        toast.error(t('package-creation.package-compare-failed'));
      }
    },
    [
      compareServiceId,
      createDocumentPackage,
      primaryData,
      primaryFiles,
      uploadFiles,
      comparePackages,
      getValues,
      secondaryFiles,
      serviceId,
      projectId,
      serviceName,
      navigate,
      setIsComparing,
      selectedComparisonPackage,
      setSecondaryData,
      packageType,
      t,
    ],
  );
  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col" translate="no">
        <span className="mt-6 mb-4 flex flex-col">
          <SecondaryService></SecondaryService>
          {compareServiceId && <SecondaryTab tabs={tab(compareServiceId)} />}
          <CompareButtons context={context}></CompareButtons>
        </span>
      </div>
    </form>
  );
};
