import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDropdown } from '@/layouts/page-header/use-dropdown';
import { fetchMessageTemplates } from '@/locales/message-templates';
import { DropdownElement } from '@/util/interfaces';

import { PageHeaderDropdown } from './page-header-dropdown';

export const TranslationMenuButton: FC = () => {
  const { t, i18n } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { isOpen, toggle, open, close, handleKeyDown } = useDropdown(dropdownRef);

  const translationOptions: DropdownElement[] = [
    {
      text: t('language.nb'),
      onClick: async () => {
        await fetchMessageTemplates('nb', 'translation');
        i18n.changeLanguage('nb');
        close();
      },
      className: i18n.language === 'nb' ? 'bg-day-light-2' : '',
    },
    {
      text: t('language.nn'),
      onClick: async () => {
        await fetchMessageTemplates('nn', 'translation');
        i18n.changeLanguage('nn');
        close();
      },
      className: i18n.language === 'nn' ? 'bg-day-light-2' : '',
    },
    {
      text: t('language.en'),
      onClick: async () => {
        await fetchMessageTemplates('en', 'translation');
        i18n.changeLanguage('en');
        close();
      },
      className: i18n.language === 'en' ? 'bg-day-light-2' : '',
    },
    {
      text: t('language.ja'),
      onClick: async () => {
        await fetchMessageTemplates('ja', 'translation');
        i18n.changeLanguage('ja');
        close();
      },
      className: i18n.language === 'ja' ? 'bg-day-light-2' : '',
    },
    {
      text: t('language.sv'),
      onClick: async () => {
        await fetchMessageTemplates('sv', 'translation');
        i18n.changeLanguage('sv');
        close();
      },
      className: i18n.language === 'sv' ? 'bg-day-light-2' : '',
    },
    {
      text: t('language.vi'),
      onClick: async () => {
        await fetchMessageTemplates('vi', 'translation');
        i18n.changeLanguage('vi');
        close();
      },
      className: i18n.language === 'vi' ? 'bg-day-light-2' : '',
    },
  ];

  return (
    <div className="relative" ref={dropdownRef} onMouseLeave={close}>
      <button
        className="py-1.5 px-2 cursor-pointer hover:bg-day-light-3 rounded-full"
        onClick={toggle}
        onMouseEnter={open}
        onKeyDown={handleKeyDown}
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <i className="fas fa-globe fa-xl text-day-neutral-dark" aria-hidden="true" />
        <span className="sr-only">Language menu</span>
      </button>
      <PageHeaderDropdown
        header={t('language.select')}
        elements={translationOptions}
        className={`right-0 ${isOpen ? 'block' : 'hidden'} min-w-[200px] z-50`}
      />
    </div>
  );
};
