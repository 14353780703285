import { type FC } from 'react';
import { Outlet } from 'react-router';

import { BreadcrumbProvider } from '@/features/breadcrumb/use-breadcrumb-context';
import { RootLayout } from '@/layouts/root-layout';

export const RootPage: FC = () => {
  return (
    <BreadcrumbProvider>
      <RootLayout>
        <Outlet />
      </RootLayout>
    </BreadcrumbProvider>
  );
};
