import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import { Breadcrumbs } from '@/features/breadcrumb/breadcrumbs';
import { useViewerContext, ViewerMode } from '@/features/document-viewer/use-viewer-context';
import { useWorkspaceContext } from '@/layouts/workspace-layout/use-workspace-context';
import { WorkspaceTabs } from '@/layouts/workspace-layout/workspace-tabs';

type WorkspaceLayoutProps = {
  children: ReactNode;
  hideTabs?: boolean;
};

export const WorkspaceLayout: FC<WorkspaceLayoutProps> = ({ children, hideTabs }) => {
  const { mode } = useViewerContext();
  const { isOverlayVisible } = useWorkspaceContext();

  return (
    <main className="flex flex-row w-full h-full bg-day-light-4">
      <div
        className={clsx(
          'flex flex-col w-full',
          mode !== ViewerMode.DocumentFullscreen && 'mx-auto',
        )}
      >
        <div className="flex flex-col">
          <div
            className={clsx(
              'flex items-center justify-center py-8 transition-colors duration-300',
              isOverlayVisible && 'bg-white',
            )}
          >
            <div className="flex flex-row grow max-w-[2000px] px-8 mx-auto">
              <Breadcrumbs />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center w-full">
            <WorkspaceTabs hideTabs={hideTabs} />
          </div>
        </div>
        <div className="flex-1 overflow-auto">
          <div className="max-w-[2000px] mx-auto h-full">{children}</div>
        </div>
      </div>
    </main>
  );
};
