import { Step, StepLayoutChildProps, useSteps } from '@consigli/facade';
import { Dispatch, FC, SetStateAction } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PackageForm } from '@/features/package-creation/components/package-form';
import { InsightForm } from '@/features/package-creation/components/user-input';

import { ComparePackages } from './compare-packages';
import { useCompareContext } from './use-compare-packages';
import { PrimaryPackageUpload } from '../primary/primary-package-upload';

interface ComparisonStepsProps {
  setIsComparing: Dispatch<SetStateAction<boolean>>;
  setTargetCounter: Dispatch<SetStateAction<number>>;
  setCurrentCounter: Dispatch<SetStateAction<number>>;
}

export const ComparisonSteps = ({
  setIsComparing,
  setCurrentCounter,
  setTargetCounter,
}: ComparisonStepsProps) => {
  const { t } = useTranslation();
  const { primaryData, setPrimaryData, secondaryData } = useCompareContext();

  const formMethods = useForm<FieldValues>({
    defaultValues: secondaryData,
  });

  const { withStep, StepOutlet, StepIndicator, useStepContext } = useSteps(
    (step: (name: string, component: FC<StepLayoutChildProps>) => Step) => {
      return [
        step(t('package-creation.details'), () => (
          <PackageForm setFormData={setPrimaryData} formData={primaryData} context={useStepContext}>
            <InsightForm></InsightForm>
          </PackageForm>
        )),
        step(t('package-creation.upload'), () => <PrimaryPackageUpload context={useStepContext} />),
        step(t('package-creation.compare'), () => (
          <FormProvider {...formMethods}>
            <ComparePackages
              setIsComparing={setIsComparing}
              context={useStepContext}
              setCurrentCounter={setCurrentCounter}
              setTargetCounter={setTargetCounter}
            />
          </FormProvider>
        )),
      ];
    },
  );
  const stepsRenderer = withStep(() => {
    return (
      <>
        <StepIndicator />
        <StepOutlet />
      </>
    );
  });
  return <>{stepsRenderer}</>;
};
