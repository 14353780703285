import { Auth, AuthGuard } from '@consigli/oauth';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  Routes,
  useParams,
} from 'react-router';

import { UserGuard } from '@/layouts/user-guard';
import { FoldersPage } from '@/pages/insight/folders/folders-page';
import { InsightDetailWrapper } from '@/pages/insight-detail-wrapper';
import { ProjectListPage } from '@/pages/project-list/project-list-page';
import { RootPage } from '@/pages/root';
import { ServiceListPage } from '@/pages/service-list/service-list-page';
import { authConfig } from '@/util/oauth/config';

import { InviteUser } from './features/invite-user/invite-user';
import { InsightAddMoreDocuments } from './features/package-creation/insight/insight-add-more-documents';
import { WizardWrapper } from './features/package-creation/wizard-wrapper';
import { ProjectCreation } from './features/project-creation/project-creation';
import { InviteCheck } from './layouts/invite-check';
import { PageNotFound } from './layouts/page-not-found';
import { CeilingGridDetailWrapper } from './pages/ceiling-grid/ceiling-grid-detail-wrapper';
import { CeilingGridInputPage } from './pages/ceiling-grid/ceiling-grid-input-page';
import { CeilingGridNavigationWrapper } from './pages/ceiling-grid/ceiling-grid-navigation-wrapper';
import { CeilingGridResultPage } from './pages/ceiling-grid/ceiling-grid-result-page';
import { EngineeringDetailWrapper } from './pages/engineering/engineering-detail-wrapper';
import { EngineeringInputPage } from './pages/engineering/engineering-input-page';
import { EngineeringNavigationWrapper } from './pages/engineering/engineering-navigation-wrapper';
import { EngineeringResultPage } from './pages/engineering/engineering-result-page';
import { ErrorPage } from './pages/error-page';
import { DashboardPage } from './pages/insight/dashboard/dashboard-page';
import { FindingsPage } from './pages/insight/findings/findings-page';
import { InsightDocumentsPage } from './pages/insight/insight-documents/insight-documents-page';
import { PropChatPage } from './pages/insight/propchat/prop-chat-page';
import { ProjectListWrapper } from './pages/project-list/project-list-wrapper';
import { ServiceDetailWrapper } from './pages/service-detail-wrapper';
import { ServiceListWrapper } from './pages/service-list/service-list-wrapper';
import { StructuralChoicePage } from './pages/structural/structural-choice';
import { StructuralDetailWrapper } from './pages/structural/structural-detail-wrapper';
import { StructuralInputPage } from './pages/structural/structural-input-page';
import { StructuralNavigationWrapper } from './pages/structural/structural-navigation-wrapper';
import { StructuralResultPage } from './pages/structural/structural-result-page';
import { TermsAndServicesPage } from './pages/terms-and-services-page';
import { UserManagementPage } from './pages/user-management/user-management-page';
import { UserManagementWrapper } from './pages/user-management/user-management-wrapper';
import { UserProfilePage } from './pages/user-profile/user-profile-page';
import { Route as NavRoute } from './routes';

const RouteRenderer = () => {
  const { serviceName } = useParams();
  if (
    serviceName === NavRoute.FLOOR_PLAN ||
    serviceName === NavRoute.CEILING_PLAN ||
    serviceName === NavRoute.PLANT_ROOM ||
    serviceName === NavRoute.SPACE_ANALYSIS ||
    serviceName === NavRoute.PARKING ||
    serviceName === NavRoute.REPORTS
  ) {
    return (
      <Routes>
        <Route path=":layoutId" element={<EngineeringDetailWrapper />}>
          <Route path={NavRoute.DASHBOARD} element={<EngineeringNavigationWrapper />} />
          <Route path={NavRoute.INPUT} element={<EngineeringInputPage />} />
          <Route path={NavRoute.RESULTS} element={<EngineeringResultPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    );
  }
  if (serviceName === NavRoute.CEILING_GRID) {
    return (
      <Routes>
        <Route path=":ceilingGridId" element={<CeilingGridDetailWrapper />}>
          <Route path={NavRoute.DASHBOARD} element={<CeilingGridNavigationWrapper />} />
          <Route path={NavRoute.INPUT} element={<CeilingGridInputPage />} />
          <Route path={NavRoute.RESULTS} element={<CeilingGridResultPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    );
  }
  if (serviceName === NavRoute.STRUCTURAL) {
    return (
      <Routes>
        <Route path=":structuralId" element={<StructuralDetailWrapper />}>
          <Route path={NavRoute.DASHBOARD} element={<StructuralNavigationWrapper />} />
          <Route path={NavRoute.INPUT} element={<StructuralInputPage />} />
          <Route path={NavRoute.RESULTS} element={<Outlet />}>
            <Route index element={<StructuralChoicePage />} />
            <Route path=":resultType" element={<StructuralResultPage />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path=":packageId" element={<InsightDetailWrapper />}>
        <Route path={NavRoute.DASHBOARD} element={<DashboardPage />} />
        <Route path={NavRoute.FINDINGS} element={<FindingsPage />} />
        <Route path={NavRoute.DOCUMENTS} element={<InsightDocumentsPage />} />
        <Route path={NavRoute.CATEGORIZATION} element={<FoldersPage />} />
        <Route path={NavRoute.PROPCHAT} element={<PropChatPage />} />
        <Route path={NavRoute.UPLOAD} element={<InsightAddMoreDocuments />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export const routes = createRoutesFromElements(
  <>
    <Route
      path="/"
      element={
        <AuthGuard authConfig={authConfig}>
          <UserGuard>
            <InviteCheck>
              <RootPage />
            </InviteCheck>
          </UserGuard>
        </AuthGuard>
      }
      errorElement={<ErrorPage />}
    >
      <Route index element={<Navigate to={`/${NavRoute.PROJECTS}`} />} />
      <Route path={`/${NavRoute.NEW_PROJECT}`} element={<ProjectCreation />} />
      <Route path={NavRoute.PROJECTS} element={<ProjectListWrapper />}>
        <Route index element={<ProjectListPage />} />
        <Route path={`:projectId/${NavRoute.SERVICES}`} element={<ServiceListWrapper />}>
          <Route index element={<ServiceListPage />} />
          <Route path=":serviceName" element={<ServiceDetailWrapper />}>
            <Route index element={<WizardWrapper />} />
            <Route path={NavRoute.CREATE} element={<WizardWrapper />} />
            <Route path={NavRoute.PACKAGES} element={<Outlet />}>
              <Route path="*" element={<RouteRenderer />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path={NavRoute.USER_MANAGEMENT} element={<UserManagementWrapper />}>
        <Route index element={<UserManagementPage />} />
        <Route path={NavRoute.INVITE_USER} element={<InviteUser />}></Route>
      </Route>
      <Route path="profile" element={<UserProfilePage />}></Route>
    </Route>
    <Route path={NavRoute.TERMS} element={<TermsAndServicesPage />} />
    <Route path="/logout" element={<Navigate to={`/${NavRoute.PROJECTS}`} />} />
    <Route
      path="/auth"
      element={
        <Auth
          destination={sessionStorage.getItem('savedPath') || `/${NavRoute.ROOT}`}
          authConfig={authConfig}
        />
      }
    />
    <Route path="*" element={<PageNotFound />} />
  </>,
);

export const router = createBrowserRouter(routes);
