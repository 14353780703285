import { LoadingArea } from '@consigli/facade';
import {
  useGetStructuralEngineeringResultsDashboardQuery,
  useProjectId,
  useStructuralId,
} from '@consigli/hooks';
import { StructuralResultType } from '@consigli/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WaitingForAnalysis } from '@/components/waiting-for-analysis';
import { Breadcrumb } from '@/features/breadcrumb/breadcrumb';
import { WorkspaceLayout } from '@/layouts/workspace-layout/workspace-layout';

import { StructuralCard } from './structural-result';

export const StructuralChoicePage: FC = () => {
  const projectId = useProjectId();
  const structuralId = useStructuralId();
  const { t } = useTranslation();

  const { data: dashboardData, isLoading } = useGetStructuralEngineeringResultsDashboardQuery({
    projectId,
    structuralId,
  });

  const getNavText = (key: string) => {
    switch (key) {
      case StructuralResultType.CAST_IN_PLACE:
        return 'cast-in-place';
      case StructuralResultType.STEEL_FRAME_WITH_HOLLOW_CORE_SLABS:
        return 'steel-frames';
      case StructuralResultType.CONCRETE_COLUMNS_WITH_HOLLOW_CORE_SLABS:
        return 'concrete-columns';
      default:
        return '';
    }
  };

  return (
    <>
      <Breadcrumb label={t('structural-tabs.results')} />
      <WorkspaceLayout>
        {isLoading ? (
          <LoadingArea title={t('document-list.loading')} />
        ) : dashboardData && Object.keys(dashboardData).length > 0 ? (
          <div className="flex justify-center pt-6 h-full">
            <div className="p-4 grid grid-cols-3 gap-6 max-w-[1200px]">
              {Object.entries(dashboardData).map(([key, value]) => (
                <div key={key} className="p-2 max-w-[400px]">
                  <StructuralCard
                    title={key}
                    concreteVolume={value.concreteVolume}
                    steelWeight={value.steelWeight}
                    navText={getNavText(key)}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <WaitingForAnalysis />
        )}
      </WorkspaceLayout>
    </>
  );
};
