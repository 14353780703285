import { useMyUser } from '@consigli/hooks';
import {
  type KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
  type FC,
} from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentFullscreenContainer } from '@/features/document-viewer/fullscreen/document/container';
import { ViewerMode, useViewerContext } from '@/features/document-viewer/use-viewer-context';
import { type Message } from '@/util/interfaces';

import { ChatMessages } from './prop-chat-messages';

interface PropChatProps {
  loading: boolean;
  loadingMessage: boolean;
  disabled: boolean;
  messages: Message[];
  onMessage: (message: Message) => void;
}

export const PropChat: FC<PropChatProps> = ({
  loading,
  loadingMessage,
  disabled,
  messages,
  onMessage,
}) => {
  const { t } = useTranslation();
  const { user } = useMyUser();
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { mode } = useViewerContext();

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [messages]);

  const sendMessage = useCallback(() => {
    if (message === '') return;

    onMessage({
      id: crypto.randomUUID(),
      body: message,
      authorId: user.id,
      createdAt: new Date(),
    });
    setMessage('');
  }, [user, message, onMessage]);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    ({ key, metaKey, ctrlKey, altKey, shiftKey }) => {
      if (key === 'Enter' && !metaKey && !ctrlKey && !altKey && !shiftKey) {
        sendMessage();
      }
    },
    [sendMessage],
  );

  return (
    <div className="flex flex-col h-full w-full bg-white shadow-xl">
      <div className="flex-1 flex flex-col p-12 overflow-y-auto text-lg">
        <ChatMessages loading={loading} disabled={disabled} messages={messages} />
        <div ref={messagesEndRef} />
      </div>

      <div>
        <input
          className="flex items-center h-10 w-full rounded-sm p-12 text-xl text-day-neutral-dark focus:outline-hidden bg-day-light-3"
          type="text"
          value={message}
          onChange={(evt) => setMessage(evt.target.value)}
          onKeyDown={onKeyDown}
          disabled={disabled || loading || loadingMessage}
          placeholder={t('prop-chat.enter-message')}
        />
      </div>
      {mode === ViewerMode.DocumentFullscreen && <DocumentFullscreenContainer />}
    </div>
  );
};
