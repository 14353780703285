import {
  useGetLayoutOptimizationsQuery,
  useLayoutId,
  useProjectId,
  useServiceName,
} from '@consigli/hooks';

import { getLayoutTypeFromServiceName } from '@/features/package-creation/optimization/optimization-wizard';

import { PackageList } from './package-list';

export const LayoutPackageList = () => {
  const projectId = useProjectId();
  const layoutId = useLayoutId();
  const serviceName = useServiceName();

  const { data: layouts = [] } = useGetLayoutOptimizationsQuery({
    projectId,
    type: getLayoutTypeFromServiceName(serviceName),
  });

  return <PackageList packages={layouts} id={layoutId} />;
};
