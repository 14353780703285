import { LoadingArea } from '@consigli/facade';
import {
  useProjectId,
  useStructuralId,
  useLazyGetStructuralEngineeringFilesQuery,
  useStructuralFiles,
} from '@consigli/hooks';
import { convertFileToDocument } from '@consigli/types';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '@/features/breadcrumb/breadcrumb';
import { DocumentListWrapper } from '@/features/document-list/document-list-wrapper';
import { WorkspaceLayout } from '@/layouts/workspace-layout/workspace-layout';

export const StructuralInputPage: FC = () => {
  const projectId = useProjectId();
  const structuralId = useStructuralId();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const { files, totalCount, isLoading, isFetching } = useStructuralFiles({
    projectId,
    structuralId,
    page: page,
  });

  const [fetchAllFiles, { isFetching: isFetchingAllFiles }] =
    useLazyGetStructuralEngineeringFilesQuery();

  const handleFetchAllFiles = useCallback(async () => {
    const structuralFilesResponse = await fetchAllFiles({
      projectId,
      structuralId,
      page: 'all',
    }).unwrap();
    return structuralFilesResponse.results.map((file) => convertFileToDocument(file));
  }, [fetchAllFiles, projectId, structuralId]);

  return (
    <>
      <Breadcrumb label={t('structural-tabs.input')} />
      <WorkspaceLayout>
        {isLoading ? (
          <LoadingArea title={t('document-list.loading')} />
        ) : files.length > 0 ? (
          <DocumentListWrapper
            files={files.map((file) => convertFileToDocument(file))}
            headerTitle={t('document-list.documents')}
            paginatedCount={totalCount}
            totalCount={totalCount}
            page={page}
            setPage={setPage}
            isFetching={isFetching}
            fetchAllFiles={handleFetchAllFiles}
            isFetchingAllFiles={isFetchingAllFiles}
            packageId={structuralId}
          />
        ) : (
          <div className="flex justify-center items-center w-full h-full text-[24px]">
            {t('document-list.no-documents')}
          </div>
        )}
      </WorkspaceLayout>
    </>
  );
};
