import { Spinner } from '@consigli/facade';
import { useProjectId, usePackageId, useBlobsById } from '@consigli/hooks';
import { convertBlobToDocument } from '@consigli/types';
import { uniqueBy } from '@consigli/utils';
import { clsx } from 'clsx';
import { FC, useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle, FaUser } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

import robot from '@/assets/images/robot.webp';
import { useViewer } from '@/features/document-viewer/use-viewer';
import { ViewerMode } from '@/features/document-viewer/use-viewer-context';
import 'react-tooltip/dist/react-tooltip.css';
import { Message } from '@/util/interfaces';

type ChatMessageProps = {
  from?: 'SYSTEM' | 'USER';
} & (
  | {
      loading?: false;
      message: Message;
    }
  | {
      loading: true;
      message?: Message;
    }
);

export const ChatMessage: FC<ChatMessageProps> = ({ message, loading, from = 'USER' }) => {
  const { t } = useTranslation();
  const tooltipId = useId();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const { blobsById } = useBlobsById(projectId, packageId);
  const { openDocumentViewer } = useViewer();

  const isSystemMessage = useMemo(() => from === 'SYSTEM', [from]);
  const icon = useMemo(() => {
    if (isSystemMessage) {
      return <img src={robot} alt="consigli silhouette" className="h-10 w-10" />;
    } else {
      return <FaUser size={32} className="text-day-neutral-dark" />;
    }
  }, [isSystemMessage]);

  return (
    <div className={clsx('flex w-full my-2', isSystemMessage ? 'justify-start' : 'justify-end')}>
      <div
        className={clsx(
          'flex gap-x-3 max-w-full',
          isSystemMessage ? 'flex-row' : 'flex-row-reverse',
        )}
      >
        <div className="flex flex-col items-center justify-center shrink-0 h-10 w-10 rounded-full text-3xl">
          {icon}
        </div>
        <div className="max-w-full">
          {loading ? (
            <Spinner size="small" />
          ) : (
            <div
              className={clsx(
                'px-6 py-2',
                isSystemMessage ? 'rounded-sm' : 'rounded-3xl bg-night-light-4 text-white',
              )}
            >
              <p className="break-words">{message.body}</p>
            </div>
          )}
          <div>
            {loading ? (
              <span className="text-sm">{t('prop-chat.thinking')}</span>
            ) : (
              <div
                className={clsx(
                  'pt-1',
                  isSystemMessage ? 'flex flex-row justify-between' : 'flex justify-end',
                )}
              >
                {isSystemMessage && message.sources && (
                  <div className="flex flex-row justify-start w-full px-6">
                    <FaInfoCircle
                      className="cursor-pointer text-day-neutral-dark"
                      data-tooltip-id={tooltipId}
                    />
                    <Tooltip className="max-w-[500px] break-normal" id={tooltipId} clickable>
                      <div>{t('prop-chat.source-list')}</div>
                      {uniqueBy(message.sources, (source) => source.blobId).map((source) => {
                        const blob = blobsById[source.blobId];
                        if (blob) {
                          return (
                            <button
                              key={blob.id}
                              onClick={() =>
                                openDocumentViewer(
                                  convertBlobToDocument(blob),
                                  ViewerMode.DocumentFullscreen,
                                )
                              }
                              className="block font-bold"
                            >
                              {blob.name}
                            </button>
                          );
                        }
                      })}
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
