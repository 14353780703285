import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '@/features/breadcrumb/breadcrumb';
import { WorkspaceProvider } from '@/layouts/workspace-layout/use-workspace-context';
import { WorkspaceLayout } from '@/layouts/workspace-layout/workspace-layout';

import { FindingsProvider } from './findings-context';
import { FindingsWrapper } from './findings-wrapper';

export function FindingsPage() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb label={t('servicetabs.findings')} />
      <WorkspaceProvider>
        <WorkspaceLayout>
          <FindingsProvider>
            <FindingsWrapper />
          </FindingsProvider>
        </WorkspaceLayout>
      </WorkspaceProvider>
    </>
  );
}
