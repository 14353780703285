import { Button, Spinner } from '@consigli/facade';
import { useServiceCategory } from '@consigli/hooks';
import { ServiceCategory } from '@consigli/types';
import { useTranslation } from 'react-i18next';
import { TbFile } from 'react-icons/tb';

import { ServiceHeader } from '@/components/service-header';

import { DownloadBuildingJsonButton } from './download-building-json-button';

interface DocumentsHeaderProps {
  totalCount: number;
  isDownloading: boolean;
  downloadAll: () => void;
  title: string;
}

export const DocumentsHeader = (props: DocumentsHeaderProps) => {
  const { totalCount, isDownloading, downloadAll, title } = props;
  const { t } = useTranslation();
  const category = useServiceCategory();

  const isCeilingGridService = category === ServiceCategory.CEILING_GRID;

  return (
    <div className="mt-4 flex items-center justify-between">
      <ServiceHeader
        icon={TbFile}
        count={totalCount}
        message={t('document-list.documents')}
        title={title}
      />
      <div className="px-4 flex gap-x-2">
        <Button
          rounded
          secondary
          disabled={isDownloading}
          onClick={downloadAll}
          icon={isDownloading ? () => <Spinner size="xsmall" /> : undefined}
        >
          {t('document-list.download-all-documents')}
        </Button>
        {isCeilingGridService && <DownloadBuildingJsonButton />}
      </div>
    </div>
  );
};
