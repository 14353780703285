import { Checkbox, Spinner } from '@consigli/facade';
import { useSelectedCard } from '@consigli/hooks';
import { DocumentsType } from '@consigli/types';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card';
import { useViewer } from '@/features/document-viewer/use-viewer';
import { ViewerMode } from '@/features/document-viewer/use-viewer-context';
import { isIFCDocumentType } from '@/util/document-check';
import { BlobFilter } from '@/util/types';

import { DocumentCard } from './document-card';
import { DocumentCardSuggestion } from './document-card-suggestion';

interface DocumentListProps {
  pageRecords: DocumentsType[];
  setPageRecords: Dispatch<SetStateAction<DocumentsType[]>>;
  selectedTab: BlobFilter | undefined;
  searchText?: string;
  isFetching: boolean;
}

export const DocumentList = ({
  pageRecords,
  setPageRecords,
  isFetching,
  searchText,
  selectedTab,
}: DocumentListProps) => {
  const { t } = useTranslation();
  const { selectedCard, setSelectedCard } = useSelectedCard('');
  const { openDocumentViewer } = useViewer();
  const getOnClickHandler = useCallback(
    (record: DocumentsType) => {
      return () => {
        setSelectedCard(record.id);
        if (isIFCDocumentType(record.extension)) {
          openDocumentViewer(record, ViewerMode.DocumentFullscreen);
        } else {
          openDocumentViewer(record, ViewerMode.DocumentPreview);
        }
      };
    },
    [setSelectedCard, openDocumentViewer],
  );
  const handleCheckboxClick = useCallback(
    (record: DocumentsType) => {
      setPageRecords((prevData: DocumentsType[]) =>
        prevData.map((item: DocumentsType) =>
          item.id === record.id ? { ...item, checked: !item.checked } : item,
        ),
      );
    },
    [setPageRecords],
  );
  return (
    <div className="overflow-auto h-[calc(100vh-29rem)]">
      {isFetching ? (
        <Spinner />
      ) : pageRecords.length === 0 ? (
        <div className="bg-day-light-3 w-full h-full flex flex-col items-center justify-center">
          <div className="text-day-neutral-subtle text-xl font-medium py-4">
            {t('document-list.no-documents-match-filter')}
          </div>
        </div>
      ) : (
        <>
          {pageRecords.map((record) => (
            <div className="flex items-center" key={record.id}>
              <div className="ml-1">
                <Checkbox
                  value={record.id.toString()}
                  label=""
                  id={record.id.toString()}
                  checked={record.checked}
                  onChange={() => handleCheckboxClick(record)}
                />
              </div>
              <div className="flex-1 ml-4">
                <Card selected={selectedCard === record.id} onClick={getOnClickHandler(record)}>
                  {selectedTab === BlobFilter.SUGGESTION ? (
                    <DocumentCardSuggestion
                      document={record}
                      selected={selectedCard}
                      searchText={searchText}
                    />
                  ) : (
                    <DocumentCard
                      document={record}
                      selected={selectedCard}
                      searchText={searchText}
                    />
                  )}
                </Card>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
