import { Button } from '@consigli/facade';
import { DocumentType } from '@consigli/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { IfcContainer } from '@/components/ifc-viewer/container';
import { ImageContainer } from '@/components/image-viewer/image-container';
import { isOptimizationService } from '@/features/document-viewer/document-preview';
import { DocumentViewer } from '@/features/document-viewer/document-viewer';
import { SinglePdfViewer } from '@/features/document-viewer/single-pdf';
import { useViewer } from '@/features/document-viewer/use-viewer';
import { useViewerContext } from '@/features/document-viewer/use-viewer-context';
import { isMsWordType, isIFCDocumentType, isImageDocumentType } from '@/util/document-check';

export const DocumentFullscreenContainer = () => {
  const { document } = useViewerContext();
  const { close } = useViewer();
  const { t } = useTranslation();
  const { serviceName } = useParams();

  const FullScreenViewerComponent = useCallback(() => {
    if (!document) {
      return (
        <div className="text-white text-center pt-[50%]">
          {t('document-viewer.no-document-selected')}
        </div>
      );
    }
    if (isImageDocumentType(document.name)) {
      return <ImageContainer document={document} />;
    }
    if (
      (isOptimizationService(serviceName) && isMsWordType(document.name)) ||
      (isMsWordType(document.name) &&
        !document.convertedPdfFileId &&
        document.type !== DocumentType.FINDING)
    ) {
      return <DocumentViewer document={document} isPreview={true} />;
    }
    if (isIFCDocumentType(document.name)) {
      return <IfcContainer document={document} />;
    }
    return <SinglePdfViewer document={document} isPreview={false} />;
  }, [document, t, serviceName]);

  return (
    <div className="flex flex-col z-[50] left-0 top-14 fixed cursor-default w-screen h-screen bg-slate-500">
      <div className="flex justify-end text-white h-8">
        <Button primary className="absolute mt-5 mr-6 py-2 px-3 border-none" onClick={close}>
          {t('findings.buttons.close')}
          <div className="fa-solid fa-x text-white"></div>
        </Button>
      </div>
      <div className="justify-center h-[calc(100vh_-_10rem)] flex">
        <FullScreenViewerComponent />
      </div>
    </div>
  );
};
