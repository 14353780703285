import { LoadingArea } from '@consigli/facade';
import {
  useProjectId,
  useStructuralId,
  useStructuralResults,
  useLazyGetStructuralEngineeringResultsQuery,
  useStructuralResultType,
} from '@consigli/hooks';
import { convertFileToDocument } from '@consigli/types';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WaitingForAnalysis } from '@/components/waiting-for-analysis';
import { Breadcrumb } from '@/features/breadcrumb/breadcrumb';
import { DocumentListWrapper } from '@/features/document-list/document-list-wrapper';
import { WorkspaceLayout } from '@/layouts/workspace-layout/workspace-layout';

export const StructuralResultPage: FC = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const structuralId = useStructuralId();
  const structuralResultType = useStructuralResultType();

  const [page, setPage] = useState(1);

  const { results, totalCount, isLoading, isFetching } = useStructuralResults({
    projectId,
    structuralId,
    page: page,
    type: structuralResultType,
  });

  const [fetchAllStructuralResults, { isFetching: isFetchingAllResults }] =
    useLazyGetStructuralEngineeringResultsQuery();

  const handleFetchAllFiles = useCallback(async () => {
    const structuralFilesResponse = await fetchAllStructuralResults({
      projectId,
      structuralId,
      page: 'all',
    }).unwrap();
    return structuralFilesResponse.results.map((result) => convertFileToDocument(result.file));
  }, [fetchAllStructuralResults, projectId, structuralId]);

  return (
    <>
      <Breadcrumb label={t('structural-tabs.results')} />
      <WorkspaceLayout>
        {isLoading ? (
          <LoadingArea title={t('document-list.loading')} />
        ) : results.length === 0 ? (
          <WaitingForAnalysis />
        ) : (
          <DocumentListWrapper
            files={results.map((result) => convertFileToDocument(result.file))}
            headerTitle={t('document-list.results')}
            paginatedCount={totalCount}
            totalCount={totalCount}
            page={page}
            setPage={setPage}
            isFetching={isFetching}
            fetchAllFiles={handleFetchAllFiles}
            isFetchingAllFiles={isFetchingAllResults}
            packageId={structuralId}
          />
        )}
      </WorkspaceLayout>
    </>
  );
};
